import { useEffect } from 'react'
import URLSearchParams from '@ungap/url-search-params'

export default () => {
  useEffect(() => {
    if (window && typeof window !== undefined) {
      const urlParams = new URLSearchParams(window.location.search)
      window.top.dispatchEvent(
        new CustomEvent('3DS-authentication-complete', {
          detail: {
            clientSecret: urlParams.get('client_secret'),
            livemode: urlParams.get('livemode'),
            source: urlParams.get('source'),
          },
        })
      )
    }
  }, [])
  return null
}
